#cookieconsent *{
    font-family: 'Trebuchet MS' !important;
    font-size: 14px;
    letter-spacing: 0;
    color: rgba(1,23,54,.8);
    text-transform: none;
    margin: initial;
}

#cookieconsent .ccContainer {
    position: fixed;
    max-width: 500px;
    top: 35%;
    left: 50%;
    right: auto;
    bottom: auto;
    z-index: 10002;
    transform: translate(-50%,-50%);
    max-height: calc(100% - 100px);
}

#cookieconsent .ccWraperBanner {
    border-radius: 4px;
    text-align: center;
    margin: 0 auto;
    padding-top: 1px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.5);
}

#cookieconsent .ccContent {
    font-weight: 400;
    font-size: 14px;
    margin-top: 23px;
    line-height: 24px;
    margin-bottom: 26px;
    color: rgba(1,23,54,.8)
}

#cookieconsent .ccLink {
    /*font-weight: bold;*/
    color: rgba(1,23,54,.8);
    text-decoration: underline;
    cursor: pointer;
}

#cookieconsent .ccWrapperButton {
    text-align: center;
}

#cookieconsent .ccButton {
    display: inline-block;
    min-width: 220px;
    padding: 15px 24px;
    margin-bottom: 24px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 525;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: all 250ms ease;
    -webkit-font-smoothing: antialiased;
    background-color:#86BF8F;
    color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

#cookieconsent .ccButton:hover{
    font-weight: bold;
    background-color:rgb(95, 161, 105);
}

#cookieconsent .ccOverlay{
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: visible;
    opacity: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow-y:scroll;
    z-index: 100999;
}

#cookieconsent .ccPopup {
    margin: 20px auto;
    padding: 20px 20px 20px;
    background: white;
    border-radius: 4px;
    position: relative;
    transition: all 2s ease-in-out;
    overflow: auto;
    min-width: 250px;
    max-width: 600px;
    text-align: center;
    line-height: 1.5em;
 }

 #cookieconsent h1{
    font-size: 18px;
    margin-bottom: 10px;
 }

 #cookieconsent h1::after{
     height: 0px;
 }

 #cookieconsent h2{
    text-decoration: underline;
    margin-bottom: 5px;
 }

#cookieconsent .ccPopup p {
    line-height: 1.5em;
    margin: initial;
}

 #cookieconsent .ccDecisions{
    text-align: left;
    color: #d2d2d2;
    font-size: 10px;
 }

 #cookieconsent .ccDecisions ul{
    list-style-type: none;
    padding-left: 0px;
    margin-left: 0px;
}